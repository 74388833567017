import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  Paragraph,
  PageWithSubNav,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="May 2023" subnav="release-notes">
      <div id="May2023" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          {/* eslint-disable-next-line max-len */}
          <q cite="https://www.quotespedia.org/authors/h/henry-kissinger/a-diamond-is-a-chunk-of-coal-that-did-well-under-pressure-henry-kissinger/">
            A diamond is a chunk of coal that did well under pressure.
          </q>{' '}
          <br />
          <span className="uni-margin--half--left uni-text--small uni-color--subtle">
            - Henry Kissinger
          </span>
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Web"
              packageName="@hudl/uniform-web"
              packageLink="https://github.com/hudl/hudl-frontends/tree/main/packages/uniform-web/README.md"
              changelogUrl="https://github.com/hudl/hudl-frontends/tree/main/packages/uniform-web/CHANGELOG.md#541"
              storybookUrl="https://uniform-web.storybook.hudltools.com"
              versionedStorybookUrl="https://63dd878b6e1bac956cef23ec-oqnkyvwvlw.chromatic.com"
              version="5.4.1"
            />
            <div className={styles.changesList}>
              <Added>
                New <code>getContentColor()</code> function for use with Uniform
                theming (Fan-team feature).
              </Added>
              <Added>
                New Subtract{' '}
                <Link
                  href="https://uniform-web.storybook.hudltools.com/?path=/story/components-icon--all-icons"
                  isExternal>
                  Icon
                </Link>
                .
              </Added>
              <Improved>
                The <code>usePrimarySecondaryColorTheme()</code> custom hook to
                use more accessible color choices.
              </Improved>
              <Fixed>
                <Link href="/components/avatars/design">Avatar</Link> components
                that weren't properly setting their secondary/background colors.
              </Fixed>
              <Fixed>
                Missing <code>IconProps</code> export for TypeScript use.
              </Fixed>
              <Fixed>
                Import issue for <code>react-select</code> that caused
                compatibility errors in the <code>@hudl/permissions</code>{' '}
                package.
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Figma" />
            <div className={styles.changesList}>
              <Improved>
                The Status <Link href="/components/spinner/design">Button</Link>{' '}
                component in Figma includes a loading variant that perpetually
                loads.
              </Improved>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
